import "./home.scss";
import "./mobileStyle.scss";
import config from "../../utils/config";
import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { pagination } from "../../utils/pagination";
import logo from "../../assets/images/circlelogo-blue.svg";
import Butext from "../../assets/images/landing-butext.svg";
import {
  Container,
  Button,
  Row,
  Col,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
// import SearchIcon from "../../assets/images/icons/searchbold.svg";
import axios from "axios";
import ClockIcon from "../../assets/images/icons/clock-white.svg";
import BuildingIcon from "../../assets/images/icons/building.svg";
import CalenderIcon from "../../assets/images/icons/calender-white.svg";
import BookIcon from "../../assets/images/icons/book-open.svg";
import CapIcon from "../../assets/images/icons/graduation-cap.svg";
import CardIcon from "../../assets/images/icons/card.svg";
import ProgramDefaultImg from "../../assets/images/course-default.jpg";
// import NewLoader from "../../widgets/loader";
import { ReactComponent as LandingBgLaptop } from "../../assets/images/laptop-video.svg";
import { ReactComponent as LaptopBgMobile } from "../../assets/images/laptop-mobile.svg";
import Skeleton from "react-loading-skeleton";
import { Tooltip } from "react-tooltip";
import languageswitch from "../../assets/images/icons/language-switch.svg";
import { useTranslation } from "react-i18next";
import UserContext from "../../features/context/user/user";


const Home: React.FC = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [allPrograms, setAllPrograms] = useState([]);
  const [languageList, setLanguageList] = useState({});
  const [selectedValue, setSelectedValue] = useState("en");
  const redirectUri = config.REDIRECT_URI;
  const [showLoader, setShowLoader] = useState(true);
  const [filesIds, setFilesIds] = useState<any>([]);
  const [filesData, setfilesData] = useState([]);
  const oAuthUrl = `${config.OAUTH2_URL}/authorize?response_type=code&client_id=moodle&redirect_uri=${redirectUri}&scope=openid&lang=${selectedValue}`;

  const [filterUpdate, setFilterUpdate] = useState<any>({
    pageNumber: 0,
    pageSize: pagination.PERPAGE,
  });
    const selectedLanguages = localStorage.getItem('selectedLanguages') && JSON.parse(localStorage.getItem('selectedLanguages'))

  useEffect(() => {
    axios
      .get(
        `${config.JAVA_API_URL}/public/programs?pageNumber=${filterUpdate.pageNumber}&pageSize=${filterUpdate.pageSize}`
      )
      .then((result: any) => {
        if (result.data !== "" && result.status === 200) {
          const firstThreeElements = result.data.items.slice(0, 3);
          setAllPrograms(firstThreeElements);
        }
      })
      .catch((err: any) => {
        setShowLoader(true);
        console.log(err);
      });
  }, [filterUpdate]);

  useEffect(() => {
    setSelectedValue(selectedLanguages !== null ? selectedLanguages : "en");
    axios
      .get(`${config.CORE_SERVICE_URL}/public/languages`)
      .then((result: any) => {
        if (result.data !== "" && result.status === 200) {
          setLanguageList(result.data);
          localStorage.setItem("languages", JSON.stringify(result.data));
        }
      })
      .catch((err: any) => {
        setShowLoader(true);
        console.log(err);
      });
  }, []);

  // console.log(languageList)

  // ============================================================
  //                      Set Files Ids
  // ============================================================

  useEffect(() => {
    let ids: any[] = [];
    allPrograms.forEach((category: any) => {
      if (category.files.length > 0) {
        category.files.forEach((file: any) => {
          ids.push({ id: file.id });
        });
      }
    });
    setFilesIds(ids);
  }, [allPrograms]);

  useEffect(() => {
    if (filesIds.length > 0) {
      axios
        .post(`${config.JAVA_API_URL}/public/files`, filesIds)
        .then((result) => {
          if (result.data !== "" && result.status === 200) {
            setfilesData(result.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [filesIds]);
  // ============================================================
  //                            End
  // ============================================================

  const getFileUrl = (programId: number) => {
    const file: any = filesData.find((file: any) => file.id === programId);
    return file ? file.url : ProgramDefaultImg;
  };

  const selectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSelectedValue(value);
    i18n.changeLanguage(value); // Function to switch between languages
  };

  useEffect(() => {
    localStorage.setItem("selectedLanguages", JSON.stringify(selectedValue));
    i18n.changeLanguage(selectedValue)
  }, [selectedValue])

  

  const userCtx = useContext(UserContext);
  const isLoggedIn = userCtx.isLoggedIn;

  return (
    <>
      <div className="landing-page">
        {isLoggedIn === false && (<div className="landing-header position-absolute ">
          <div className="d-flex justify-content-between align-items-center">
            <div className="logo-wrapper">
              <img src={logo} alt="logo" className="img img-fluid" />
            </div>
            <div className="switch-role">
              <OverlayTrigger
                trigger={["click"]}
                placement="bottom"
                overlay={
                  <Popover id="popover-basic">
                    <Popover.Header as="h3">{t('common.switchLang')}</Popover.Header>
                    <Popover.Body>
                      <select
                        className="form-select"
                        value={selectedValue}
                        // onChange={getCurrentValue}
                        onChange={(e) => selectHandler(e)}
                      >
                        {Object.entries(languageList).map(
                          ([key, value]: any, index: number) => (
                            <option
                              key={index}
                              value={key}
                              data-name={value}
                              // data-priority={el.rolePriority}
                            >
                              {value}
                            </option>
                          )
                        )}
                      </select>
                    </Popover.Body>
                  </Popover>
                }
                rootClose
              >
                <Button
                  variant="link"
                  className="head-icon my-lang-element"
                  data-tooltip-variant="light"
                  title=""
                >
                  <img src={languageswitch} alt="Switch Role" />
                </Button>
              </OverlayTrigger>
              <Tooltip
                anchorSelect=".my-lang-element"
                content="Switch Languages"
                place="bottom"
                style={{ zIndex: 2 }}
              />
            </div>
            {/* <button className="search-btn bg-dark">
                  <img src={SearchIcon} alt="logo" className="img img-fluid" />
                </button> */}
          </div>
        </div>)}

        <div className="landing-wrapper">
          <Container fluid>
            <div className="landing-content mt-3">
              <img src={Butext} alt="Ballistic University" className="butext" />
              <LaptopBgMobile className="w-100 laptopbg-mobile" />
              <div className="bgbtn-wrapper">
                <div className="login-btn mt-5">
                  <a href={oAuthUrl}>
                    <Button variant="btn-lg rounded-pill px-4">
                      {t("common.login")}
                    </Button>
                  </a>
                  <Link to={`/signupnew?lang=${selectedLanguages}`}>
                    <Button variant="btn-lg rounded-pill px-4 m-3 signup">
                      {t("common.singUp")}
                    </Button>
                  </Link>
                </div>
                <LandingBgLaptop className="w-100 laptopbg" />
              </div>
              <div>
              </div>
            </div>
          </Container>
        </div>
        <div className="footer-links">
          <Container fluid>
            <Row>
              <Col xs={12} md={4}>
                {t("common.trp")}{" "}
              </Col>
              <Col xs={12} md={4}>
                {t("common.naac")}
              </Col>
              <Col xs={12} md={4}>
                {t("common.prsnLearnPath")}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className="landing-courses d-flex">
        <div className="title-wrapper">
          <h4>{t("common.program")}</h4>
          <div>{t("common.programDetails")}</div>
          <button
            className="btn-lg rounded-pill px-4 explorer-btn"
            onClick={() => navigate("/programlist")}
          >
            {t("common.exploreAllPrg")}
          </button>
        </div>
        <div className="courseswrapper">
          <Row className="landingprograms">
            {allPrograms && allPrograms.length === 0 ? (
              <div className="d-flex landing-skeleton">
                <div>
                  <Skeleton height={"18rem"} />
                </div>
                <div>
                  <Skeleton height={"18rem"} />
                </div>
                <div>
                  <Skeleton height={"18rem"} />
                </div>
              </div>
            ) : (
              allPrograms.map((item: any, index: number) => (
                <Col key={item.id} xl={4} lg={4} md={6} sm={12}>
                  <Link
                    key={item.id}
                    to={{ pathname: `/programsummary/${item.id}` }}
                  >
                    <div className="course-container" key={index}>
                      <div className="course-image">
                        <img
                          src={getFileUrl(item.files[0]?.id)}
                          alt={item.name}
                          className="img img-fluid"
                        />
                        <div className="course-keypoints">
                          <div>
                            <img src={CalenderIcon} alt="batch year" />
                            <span> {item.batchYear}</span>
                          </div>
                          <div>
                            <img src={CardIcon} alt="program code" />
                            <span>{item.programCode}</span>
                          </div>
                          <div>
                            <img src={BuildingIcon} alt="department" />
                            <span> {item.department.name}</span>
                          </div>
                          <div>
                            <img src={BookIcon} alt="study mode" />
                            <span>{item.modeOfStudy}</span>
                          </div>
                          <div>
                            <img src={CapIcon} alt="program type" />
                            <span> {item.programType.name}</span>
                          </div>
                          <div>
                            <img src={ClockIcon} alt="duration" />
                            <span> {item.durationValue}{" "} {item.durationUnit}</span>
                          </div>
                        </div>
                      </div>
                      <div className="course-title">{item.name}</div>
                    </div>
                  </Link>
                </Col>
              ))
            )}
          </Row>

          <div className="allcourses-btn">
            {/* <button onClick={() => navigate("/programlist")}>
            <i className="fa fa-angle-right"></i>
          </button> */}
            <button
              className="btn-lg rounded-pill px-4"
              onClick={() => navigate("/programlist")}
            >
              {t('catalogue.explorePrograms')} <i className="fa fa-angle-right"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
