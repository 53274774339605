import React, { useContext } from "react";
import UserContext from "../../context/user/user";
import { Navigate, Route } from "react-router-dom";
import Home from "../../../pages/home/Home";
import AuthLogin from "../../../pages/authlogin/AuthLogin";
import { useSelector } from "react-redux";

const Logout = React.lazy(() => import("../../../pages/logout"))
const SignUpNew = React.lazy(() => import("../../../pages/signupNew"))

const AuthRoute = () => {
  const userCtx = useContext(UserContext);
  const isLoggedIn = userCtx.isLoggedIn;

  const currentUserRole = useSelector((state: any) => state.globalFilters.currentUserRole);
  const permissions = useSelector((state: any) => state.userAuthorities.permissions);

  return [
    <Route
      path="/"
      element={isLoggedIn === false ? <Home /> : <Navigate  to={`${(permissions.menu.dashboard.canView && currentUserRole.shortName !== "admin") ? "/dashboard" : "/performance"}`} />}
    />,
    <Route path="/logout" element={<Logout />} />,
    <Route path="/authlogin" element={<AuthLogin />} />,
    <Route path="/signupnew" element={<SignUpNew />} />,
  ];
};

export default AuthRoute;