import React, { createContext, useState, useContext } from "react";
import RolePermissionAlertComp from "../../../widgets/alert/rolePermissionAlert";

interface ModalContextProps {
  showModal: (
    message: string,
    onConfirm: () => void,
    // onCancel?: () => void,
    title: string
  ) => void;
  closeModal: () => void;
}

const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export const ModalProvider: React.FC = ({ children }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [onConfirm, setOnConfirm] = useState<() => void>(() => () => {});
  // const [onCancel, setOnCancel] = useState<() => void>(() => () => {});

  const showModal = (message: string, confirm: () => void, title: string) => {
    setModalMessage(message);
    setModalTitle(title);
    setOnConfirm(() => confirm);
    // setOnCancel(() => cancel);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <ModalContext.Provider value={{ showModal, closeModal }}>
      {children}
      <RolePermissionAlertComp
        title={modalTitle}
        message={modalMessage}
        onConfirm={() => {
          onConfirm();
          closeModal();
        }}
        // onCancel={() => {
        //   onCancel();
        //   closeModal();
        // }}
        isOpen={isOpen}
      />
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};
