import "./style.scss";
import { Modal } from "react-bootstrap";
import MailIcon from "../../assets/images/icons/mail.svg";
import RequestImage from "../../assets/images/price-request.png";
import WaveBottom from "../../assets/images/background/bg-modal.svg";
import { useTranslation } from "react-i18next";

type Props = {
  show : boolean,
  handleClose: any,
  currentUserRole?: any
}

const PriceRequestModal = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Modal show={props.show} onHide={props.handleClose} centered size="lg" className="pricerequest-wrapper">
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
      <div className="d-flex p-2 align-items-center gap-5">
        <div className="d-flex flex-column gap-2 requestmodal-content">
            {/* <h4>Price Upon Request</h4> */}
            {props.currentUserRole?.id > 0 ? <h4>Price Upon Request</h4> : <h5>{t('dashbord.permissionPopup')}</h5>}
            <div className="d-flex flex-row gap-2">
              <img src={MailIcon} alt="Mail" className="img img-fluid" />
              <a href="mailto:enquiry@ballisticlearning.com">enquiry@ballisticlearning.com</a>
            </div>
        </div>
        <div className="request-image">
            <img src={RequestImage} alt="Price Request"/>
        </div>
      </div>
      </Modal.Body>
      <img src={WaveBottom} alt="WaveBottom" className="wavebg"/>
    </Modal>
  );
};

export default PriceRequestModal;
