import React from "react";
import { Route } from "react-router-dom";

const UserProfile = React.lazy(() => import("../../../pages/user/profile"))
const EditProfile = React.lazy(() => import("../../../pages/user/profile/forms/editProfile"))

const ProfileRoute = () => {
  return [
    <Route path="/profile" key="profile" element={<UserProfile />} />,
    <Route path="/editprofile" key="editprofile" element={<EditProfile />} />
  ];
};

export default ProfileRoute;