import React from "react";
import View from "./view";

const CopoTabsList = () => {

  return (
    <React.Fragment>
      <View />
    </React.Fragment>
  );
};

export default CopoTabsList;
