import React from "react";
import { Modal, Button } from "react-bootstrap";
import WaveBottom from "../../assets/images/background/bg-modal.svg";
import { useTranslation } from "react-i18next";
interface IDeleteAlert {
  show: boolean;
  onHide: () => void;
  deleteActionResponse: (params: string) => void;
  modalHeading: string;
  titlePrefix?: string;
  atertMessage?: string;
}

const DeleteAlert: React.FC<IDeleteAlert> = ({
  show,
  onHide,
  deleteActionResponse,
  modalHeading,
  titlePrefix,
  atertMessage
}: IDeleteAlert) => {

 const {t} = useTranslation()
  const deleteHandler = (e: any) => {
    if (e.type === "click") {
      deleteActionResponse(e.target.innerHTML);
    }
  };

  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={onHide}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-design-wrapper"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {titlePrefix !== undefined ? titlePrefix : t('common.delete')} {modalHeading}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3 text-center">
            {/* {atertMessage} */}
            {atertMessage !== undefined ? atertMessage : t('common.doYouWantDelete')}
          </div>
          <div className="modal-buttons">
            <Button variant="primary" onClick={(e) => deleteHandler(e)}>
        {t('common.yes')}
            </Button>
            <Button variant="secondary" onClick={(e) => deleteHandler(e)}>
            {t('common.no')}
            </Button>
          </div>
        </Modal.Body>
        <img src={WaveBottom} alt="WaveBottom" className="wavebg" />
      </Modal>
    </React.Fragment>
  );
};

export default DeleteAlert;
