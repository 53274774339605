import { Button, Modal } from "react-bootstrap";
import WaveBottom from "../../assets/images/background/bg-modal.svg";
interface ModalProps {
  title: string;
  message: string;
  onConfirm: () => void;
  // onCancel?: () => void;
  isOpen: boolean;
}

const RolePermissionAlertComp: React.FC<ModalProps> = ({
  title,
  message,
  onConfirm,
  // onCancel,
  isOpen,
}) => {
  if (!isOpen) return null;

  return (
    <Modal show={isOpen} centered size="lg" className="pricerequest-wrapper">
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <div className="d-flex p-2 align-items-center gap-5">
          <div className="d-flex flex-column gap-2 requestmodal-content">
            <h4>{title}</h4>
            <p>{message}</p>
            <div className="d-flex flex-row gap-2"></div>
          </div>
          <Button variant="primary" type="submit" onClick={onConfirm}>
            Proceed
          </Button>
        </div>
      </Modal.Body>
      <img src={WaveBottom} alt="WaveBottom" className="wavebg" />
    </Modal>
  );
};

export default RolePermissionAlertComp;
