import React from 'react'
import { Route } from 'react-router-dom'

const HostelManagement = React.lazy(() => import('../../../pages/hostelManagement'))
const ManageHostel = React.lazy(() => import('../../../pages/hostelManagement/manageHostel'))

const HostelManagementRoute = () => {
  return [
    <Route key='hostelmanagement' path='/hostelmanagement' element={<HostelManagement />} />,
    <Route key='managehostel' path='/managehostel' element={<ManageHostel />} />,

  ]
}

export default HostelManagementRoute