import React from "react";
import { Route } from "react-router-dom";

const ProgramList = React.lazy(() => import("../../../pages/home/programList"))
const ProgramSummary = React.lazy(() => import("../../../pages/home/programSummary"))

const HomePageRoute = () => {
  return [
    <Route path="/programlist" key="programlist" element={<ProgramList />} />,
    <Route path="/programsummary/:Programid" key="programsummary" element={<ProgramSummary />} />
  ];
};

export default HomePageRoute;