import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import DashboardIcon from "../../assets/images/icons/mb-dashboard-icon.svg";
import DashboardIconActive from "../../assets/images/icons/mb-dashboard-icon-active.svg";
import CalenderIcon from "../../assets/images/icons/mb-calender-icon.svg";
import CalenderIconActive from "../../assets/images/icons/mb-calender-icon-active.svg";
import GradebookIcon from "../../assets/images/icons/mb-gradebook-icon.svg";
import GradebookIconActive from "../../assets/images/icons/mb-gradebook-icon-active.svg";
import PerformanceIcon from "../../assets/images/icons/mb-performance-icon.svg";
import PerformanceIconActive from "../../assets/images/icons/mb-performance-icon-active.svg";
import MenuIcon from "../../assets/images/icons/mb-menu-icon.svg";
import "./mobileStyle.scss";
import Sidebar from "./sidebar";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
type Props = {};

const MobileFooter = (props: Props) => {
  const {t} = useTranslation();
  const location = useLocation();
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  const toggleSidebar = () => {
    setSidebarVisible(!isSidebarVisible);
  };
  const closeSidebar = () => {
    setSidebarVisible(false);
  };
  const menuPermission = useSelector(
    (state: any) => state.userAuthorities.permissions.menu
  );
  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );

  const footerList = [
    {
      activeIcon: DashboardIconActive,
      inactiveIcon: DashboardIcon,
      title: t('common.dashboard'),
      link: "/dashboard",
    },
    {
      activeIcon: PerformanceIconActive,
      inactiveIcon: PerformanceIcon,
      title: t('common.performance'),
      link: "/performance",
    },
    {
      activeIcon: CalenderIconActive,
      inactiveIcon: CalenderIcon,
      title: t('common.calendar'),
      link: "/calender", 
    },
    {
      activeIcon: GradebookIconActive,
      inactiveIcon: GradebookIcon,
      title: t('common.gradebook'),
      link: "/gradebook",
    },
    {
      activeIcon: MenuIcon,
      inactiveIcon: MenuIcon,
      title: t('common.menu'),
      link: "#",
    },
  ];

  const renderFooterIcon = (item: { activeIcon: any; inactiveIcon: any; title: any; link: any; }) => (
    <>
      <img 
        src={location.pathname === item.link ? item.activeIcon : item.inactiveIcon} 
        alt={item.title} 
      />
      <h2 className={`footerTitle ${location.pathname === item.link ? "active" : ""}`}>
        {item.title}
      </h2>
    </>
  );

  return (
    <footer className="md-stickyFooter">
      <div>
        <ul className="d-flex justify-content-between align-items-center p-0 m-0">
          {footerList.map((item, index) => (
            <Link to={item.link} key={index}>
              <li
                className={`text-center list-unstyled md-footer-list ${location.pathname === item.link ? "active" : ""}`}
                onClick={item.title === t('common.menu') ? toggleSidebar : closeSidebar}
              >
                {menuPermission.dashboard.canView && currentUserRole.shortName !== "admin" 
                  ? renderFooterIcon(item) 
                  : item.title !== t('common.dashboard') && renderFooterIcon(item)
                }
              </li>
            </Link>
          ))}
        </ul>
      </div>
      {isSidebarVisible && <Sidebar onClose={closeSidebar} />}
    </footer>
  );
};

export default MobileFooter;

