import React from "react";
import ProgramEnrollment from "./programEnrollment";


const CoPoManagement = () => {


  return (
    <React.Fragment>
       <ProgramEnrollment/>
    </React.Fragment>
  );
};

export default CoPoManagement;
